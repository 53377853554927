/* eslint-disable */

import { GenericModel } from '@/shared/model/generic-model';
import { i18n } from '@/locales/i18n';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import JsonField from '@/shared/fields/json-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import DateTimeField from '@/shared/fields/date-time-field';
// import EnumeratorField from '@/shared/fields/enumerator-field';
// import IntegerField from '@/shared/fields/integer-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`category.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  image: new StringField('image', label('image')),
  name: new JsonField('name', label('name'), ['en', 'ar']),

  // createdAt: new DateTimeField('createdAt', label('createdAt')),
  // updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  // createdBy: new StringField('createdBy', label('createdBy')),
  // updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class CategoryModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
