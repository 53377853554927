<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-lg font-medium mr-auto">
            <app-i18n code="category.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="category.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('category.edit.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.en"
                    :placeholder="i18n('category.edit.nameInEnglish')"
                  />
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <input
                    id="title-ar"
                    type="text"
                    class="form-control w-full dark:bg-dark-9 dark:placeholder-white"
                    v-model="model.name.ar"
                    :placeholder="i18n('category.edit.nameInArabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center" v-if="isEditing">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('category.edit.image') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="imageUrl">
                    <img
                      :src="imageUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.image = undefined"
                    />
                  </div>
                  <Uploader ref="uploader" :id="id" v-else></Uploader>
                </div>
              </div>
              <!-- END -->
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Message from '@/shared/message/toastify'
import { mapGetters, mapActions } from 'vuex'
import { FormSchema } from '@/shared/form/form-schema'
import Uploader from '@/views/category/uploader.vue'
import { CategoryModel } from '@/store/category/category-model'

const { fields } = CategoryModel
const formSchema = new FormSchema([fields.id, fields.name, fields.image])

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'category/form/record',
      findLoading: 'category/form/findLoading',
      saveLoading: 'category/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSave() {
      return this.$refs.uploader?.filesToSave || []
    },
    imageUrl() {
      return this.model && this.model.image ? this.model.image : null
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = formSchema.initialValues(this.record || {})
    if (this.isEditing) {
      this.model.name = this.record.name
    }
  },
  methods: {
    ...mapActions({
      doNew: 'category/form/doNew',
      doFind: 'category/form/doFind',
      doCreate: 'category/form/doCreate',
      doUpdate: 'category/form/doUpdate'
    }),
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(...this.errorMessage.split('-'))
        return Message.error(ERROR)
      }
      const { id, name } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          name,
          image: this.imageUrl || this.filesToSave[0]
        })
      } else {
        await this.doCreate(name)
      }
    },
    doCancel() {
      this.$router.push('/category')
    },
    isFormValid() {
      if (this.model.name.en.length == 0 || this.model.name.ar.length == 0) {
        this.errorMessage = `notifications.create.popup.emptyFields-${
          this.isRTL ? 'اسم' : 'name'
        }`
        return false
      }
      if (this.isEditing) {
        if (!(this.imageUrl || this.filesToSave.length !== 0)) {
          this.errorMessage = `notifications.create.popup.emptyFields-${
            this.isRTL ? 'الصوره' : 'image'
          }`
          return false
        }
      }
      return true
    },
    i18n(key, ...args) {
      return this.$t(key, args)
    }
  }
}
</script>
